<script lang="ts" setup>
import { FieldControl, FieldItem, FieldLabel, FormField, Radio, RadioGroup } from '@dailycare-au/ui'
import { organisationStore } from '@/stores/organisation';
import { useProvideReportFilters } from './filters';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

const { period } = useProvideReportFilters()
const { organisation } = storeToRefs(organisationStore())

const organisationName = computed(() => organisation.value?.name ?? 'Organisation')
</script>

<template>
  <div class="native-reports grid grid-cols-5 gap-4">
    <h3 class="subtitle is-1 col-span-full mb-0">{{organisationName}} overall performance</h3>
    <ReportsNativeCommonPeriodSelect v-model="period" />

    <ReportsNativeOverview />

    <ReportsNativeOverallPerformance />
    <ReportsNativePerformanceByHospital />

    <hr class="col-span-full my-16">

    <ReportsNativeFacility />
  </div>
</template>

<style lang="scss">
  /**
   * unfortunately required to override bulmas forced utility classes
   * scoping also doesn't work here...
  */

  div.native-reports {
    .p-6 { padding: 1.5rem !important; }
    .pt-0 { padding-top: 0 !important; }
  }
</style>