<script lang="ts">

</script>

<script lang="ts" setup>
import { h, ref } from 'vue';
import { useFacilityReportFilters } from './filters';
import { computedAsync } from '@vueuse/core';
import { getListingPerformance } from '@/api/reports.api';
import { Datatable } from '@dailycare-au/visualise';
import type { ListingPerformance } from '@/api/reports.api';
import { createColumnHelper } from '@tanstack/vue-table';
import ScoreCell from '../common/scoreCell.vue';

const { period } = useFacilityReportFilters()

const loading = ref(false)
const data = computedAsync(async () => {
  const { data } = await getListingPerformance(period.value, 'this')
  return data
}, [], { evaluating: loading })

const helper = createColumnHelper<ListingPerformance>()
const columns = [
  helper.accessor('listing_name', { header: 'Facility' }),
  helper.accessor('avg_vacancy_update', { header: 'Avg. Vacancy Update Frequency', cell: ({ getValue }) => `${getValue()?.toFixed(0)} days` }),
  helper.accessor('vacancy_updated', { header: 'Vacancy Freshness Score', cell: ({ cell }) => h(ScoreCell, { value: cell.getValue(), suffix: '%' }) }),
  helper.accessor('page_one_appearances', { header: 'Search Results Page One' }),
  helper.accessor('referrals', { header: 'Patient Shortlists' }),
  helper.accessor('referrals_rank', { header: 'Patient Shortlists Score', cell: ({ cell }) => h(ScoreCell, { value: cell.getValue(), suffix: '%' }) }),
  helper.accessor('page_one_appearances_rank', { header: 'Search Results Page One Score', cell: ({ cell }) => h(ScoreCell, { value: cell.getValue(), suffix: '%' }) }),
]
</script>

<template>
  <div>
    <h3 class="subtitle is-3">Individual Facility Performance</h3>
    <Datatable :data="data" :columns="columns" />
  </div>
</template>