<script lang="ts" setup>
import { useProvideFacilityReportFilters } from './filters';

const { period } = useProvideFacilityReportFilters()
</script>

<template>
  <section class="w-full col-span-full">
    <h3 class="subtitle is-1 col-span-full mb-4">Performance by facility</h3>
    <ReportsNativeCommonPeriodSelect v-model="period" />

    <div class="space-y-16">
      <ReportsNativeFacilityPerformance />

      <ReportsNativeFacilityPerformanceByHospital />
    </div>
  </section>
</template>
