import { createInjectionState } from '@vueuse/core'
import { Period } from '@/api/reports.api'
import { ref } from 'vue'

const injectionKey = Symbol('facilityReportsFilters')
const [useProvideFacilityReportFilters, useInjectFacilityReportFilters] = createInjectionState(() => {
  const period = ref<Period>('7day')

  return { period }
}, { injectionKey })

function useFacilityReportFilters() {
  const filters = useInjectFacilityReportFilters()
  if (!filters) throw new Error('useFacilityReportFilters is called outside of the native reports')

  return filters
}

export { useProvideFacilityReportFilters, useFacilityReportFilters }