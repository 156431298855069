<script lang="ts">

export interface PeriodSelectProps {
  periods?: { label: string; value: Period }[]
  modelValue: Period
}

export interface PeriodSelectEmits {
  (e: 'update:modelValue', value: Period): void
}
</script>

<script lang="ts" setup>
import { Period } from '@/api/reports.api';
import { FieldControl, FieldItem, FieldLabel, FormField, Radio, RadioGroup } from '@dailycare-au/ui'
import { useVModel } from '@vueuse/core'

const props = withDefaults(defineProps<PeriodSelectProps>(), {
  periods: () => [
    { label: 'Last 7 days', value: '7day' },
    { label: 'Last 30 days', value: '30day' },
    { label: 'Last 90 days', value: '90day' },
    { label: 'Last 12 months', value: '365day' },
  ]
})

const emits = defineEmits<PeriodSelectEmits>()
const period = useVModel(props, 'modelValue', emits)
</script>

<template>
  <FormField v-model="period" v-slot="{ componentField }" name="period" type="radio">
    <RadioGroup v-bind="componentField" class="flex gap-4 items-center col-span-full mb-16">
      For:
      <FieldItem v-for="period of periods" :key="period.value" inline>
        <FieldControl>
          <Radio :value="period.value" />
        </FieldControl>
        <FieldLabel>{{ period.label }}</FieldLabel>
      </FieldItem>
    </RadioGroup>
  </FormField>
</template>